@import '~antd/dist/antd.less';

body {
  font-family: 'Roboto', sans-serif;
}

html,
body {
  height: inherit;
}

pre {
  margin-bottom: 0em !important;
  font-family: 'Roboto', sans-serif !important;
}

.ant-form-item-label {
  padding: 0 0 0 0 !important;
  label {
    font-weight: 500;
    color: #777;
  }
}

.ant-menu-horizontal {
  border-bottom: unset;
}

@media (prefers-color-scheme: dark) {
  .rdg {
    --rdg-font-size: 12px !important;
  }
  .rdg:not(.rdg-light) {
    --color: #ddd;
    --border-color: #444;
    --summary-border-color: #555;
    --background-color: #222;
    --header-background-color: #1c1c1c;
    --row-hover-background-color: #181818;
    --row-selected-background-color: #1a73bc;
    --row-selected-hover-background-color: #1868aa;
    --checkbox-color: #95cfff;
    --checkbox-focus-color: #c8e6ff;
    --checkbox-disabled-border-color: #000;
    --checkbox-disabled-background-color: #333;
  }
}

@btn-primary-bg: #071E59;@text-color: #071E59;@menu-highlight-color: #071E59;@font-size-base: 12px;@font-family: "Roboto", sans-serif;@height-base:  30px;@height-lg:  38px;@height-sm:  22px;@table-padding-vertical: 4px;@form-item-margin-bottom: 14px;@form-vertical-label-padding:  0 0 6px;